<template>
  <div>
    <router-link class="come-back" to="/admin/live_stream/">
      <div></div>
      Вернуться назад
    </router-link>

    <h2 class="title_categories">
      {{ isEdit ? `Редактирование прямой трансляции ${this.$route.params.id}`
      : 'Создание прямой трансляции' }}
    </h2>
    <tricolor-line class="card_containet__tricolor"/>

    <form @submit.prevent="createLiveStream" ref="createLiveStream">
      <div>
        <div class="content-sb">
          <p>Ссылка на трансляцию</p>
          <input placeholder="Введите ссылку" v-model="liveStreamData.live_stream" type="text" name="live_stream" class="input blue">
        </div>

        <div class="content-sb">
          <p>Заголовок<span>*</span></p>
          <input required placeholder="Введите заголовок" v-model="liveStreamData.title" type="text" name="title" class="input blue">
        </div>

        <div class="content-sb">
          <p>Заголовок (Англ)</p>
          <input placeholder="Введите заголовок" v-model="liveStreamData.title_eng" type="text" name="title_eng" class="input blue">
        </div>

        <div class="content-sb">
          <p>Описание</p>
          <input placeholder="Введите описание" v-model="liveStreamData.description" type="text" name="description" class="input blue">
        </div>

        <div class="content-sb">
          <p>Описание (Англ)</p>
          <input placeholder="Введите описание" v-model="liveStreamData.description_eng" type="text" name="description_eng" class="input blue">
        </div>

        <div class="content-sb">
          <p>Дата начала<span>*</span></p>
          <input required placeholder="Введите дату" v-model="liveStreamData.start_date_time" type="datetime-local" name="start_date_time" class="input blue">
        </div>

        <div class="content-sb">
          <p>Дата окончания<span>*</span></p>
          <input required placeholder="Введите дату" v-model="liveStreamData.end_date_time" type="datetime-local" name="end_date_time" class="input blue">
        </div>

        <div class="content-sb">
          <p>Рубрика<span>*</span></p>
          <select name="rubric" v-model="liveStreamData.rubric" class="input blue">
            <option v-for="(rubric,index) in getRubrics" :value="rubric.id" :key="`rubric${index}`" :selected="index === 0">
              {{ rubric.title }}
            </option>
          </select>
        </div>

        <div class="content-sb">
          <p>Теги</p>
          <tags-list-search :tags="getTagsList" @setTags="setTags"/>
        </div>

        <div class="content-sb">
          <p>Текст</p>
          <ckeditor :config="$editorConfig" :editor-url="$root.editorUrl" v-model="liveStreamData.text" class="wysiwyg"/>
        </div>

        <div class="content-sb">
          <p>Текст (Англ)</p>
          <ckeditor :config="$editorConfigEng" :editor-url="$root.editorUrl" v-model="liveStreamData.text_eng" class="wysiwyg"/>
        </div>

        <div class="content-sb">
          <p>Превью<span>*</span></p>
          <custom-drop-zone :name="'preview_media'"
                            @setFile="setFile($event, ['liveStreamData','preview_media'])"
                            :uploaded-blob="previewBlob"/>
        </div>
      </div>

      <footer class="content-sb btns-create-form">
        <router-link to="/admin/live_stream" class="btn-white">Отмена</router-link>

        <input type="submit" class="btn-blue" value="Сохранить"/>
      </footer>
    </form>
  </div>
</template>

<script>
import TricolorLine from '@/components/TricolorLine'
import CustomDropZone from '@/components/Admin/CustomDropZone'
import TagsListSearch from '@/components/news/TagsListSearch'

export default {
  name: 'CreateLiveStream',

  props: {
    isEdit: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data () {
    return {
      previewBlob: null,
      liveStreamData: {
        live_stream: null,
        title: null,
        title_eng: null,
        description: null,
        description_eng: null,
        start_date_time: null,
        end_date_time: null,
        tags_list: null,
        text: null,
        text_eng: null,
        rubric: 1,
        preview_media: null
      }
    }
  },

  watch: {
    getAdminLiveStream () {
      const dataFromServer = this.getAdminLiveStream

      this.liveStreamData = {
        live_stream: dataFromServer.live_stream,
        title: dataFromServer.title,
        title_eng: dataFromServer.title_eng,
        description: dataFromServer.description,
        description_eng: dataFromServer.description_eng,
        start_date_time: dataFromServer.start_date_time ? dataFromServer.start_date_time.split(' ').join('T').substring(0, 16) : null,
        end_date_time: dataFromServer.end_date_time ? dataFromServer.end_date_time.split(' ').join('T').substring(0, 16) : null,
        text: dataFromServer.text,
        text_eng: dataFromServer.text_eng,
        rubric: dataFromServer.rubric,
        tags_list: dataFromServer.tags_list ? dataFromServer.tags_list.join('/') : ''
      }

      this.previewBlob = dataFromServer.preview_media
    }
  },

  created () {
    if (this.isEdit) {
      this.$store.dispatch('getAdminLiveStream', this.$route.params.id)
    }
    this.$store.dispatch('getLiveStreams')
    this.$store.dispatch('getRubrics')
  },

  methods: {

    createLiveStream () {
      const formData = new FormData()

      for (const key in this.liveStreamData) {
        if (this.liveStreamData[key] !== null) {
          formData.append(key, this.liveStreamData[key])
        }
      }

      console.debug(...formData)

      if (!this.isEdit) {
        this.$store.dispatch('createLiveStream', formData)
      } else {
        formData.append('_method', 'PUT')
        this.$store.dispatch('updateLiveStream', {
          data: formData,
          id: this.$route.params.id
        })
      }
    },

    setFile (file, key) {
      if (key.length === 1) {
        this[key[0]] = file
      } else if (key.length === 2) {
        this.previewBlob = null
        this[key[0]][key[1]] = file
      }
    },

    setTags (tags) {
      this.liveStreamData.tags_list = tags.join('/')
    }

  },
  computed: {

    getRubrics () {
      return this.$store.getters.getRubrics
    },

    getLiveStreams () {
      return this.$store.getters.getLiveStreams
    },

    getAdminLiveStream () {
      return this.$store.getters.getAdminLiveStream
    },

    getTagsList () {
      if (this.liveStreamData.tags_list) {
        return this.liveStreamData.tags_list.split('/').filter(tag => !!tag)
      } else {
        return []
      }
    }

  },
  components: {
    TagsListSearch,
    CustomDropZone,
    TricolorLine
  }
}
</script>

<style scoped lang="scss">
  @import "../../../assets/scss/utils/vars";

  .wysiwyg {
    width: 33.75rem;
  }

  .btns-create-form {
    border-top: 1px solid #D5D6D9;
    padding-top: 2.5rem;
    margin-top: 2.5rem;
  }

  .content-sb {
    margin-bottom: 1.25rem;

    span {
      color: red;
    }
  }

  .select, .input {
    width: 33.75rem;
  }

  .inputs__container {
    margin-bottom: 2rem;
    border-bottom: 1px solid #D5D6D9;
  }

  .content-blocks__container {
    display: flex;
    flex-direction: column;

    > .content-block {
      margin-bottom: 1.875rem;

      header {
        margin-bottom: .75rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        > h4 {
          font-size: .875rem;
          line-height: 1.375rem;
          color: $mainFontColor;
        }

        > img {
          width: 1.5rem;
          height: 1.5rem;
          object-fit: contain;
          object-position: center;
          cursor: pointer;
        }
      }

      .data-card {
        background: white;
        border: 1px solid #EBF3FF;
        box-sizing: border-box;
        box-shadow: 0 .9375rem 1.5625rem rgba(189, 205, 221, 0.15);
        border-radius: .5rem;
        padding: 1.25rem;
        width: 100%;
        min-height: 8.875rem;
      }
    }
  }

  .add-content-block__container {
    background-color: $neutral-placeholder;
    width: 100%;
    padding: 1.25rem;
    border-radius: .187rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-top: .5rem;

    &:after {
      content: '';
      position: absolute;
      width: 1.5rem;
      height: 1.5rem;
      background-color: $neutral-placeholder;
      top: 0;
      left: 50%;
      transform: translate(-50%,-50%) rotate(45deg);
    }

    > .row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.25rem;
    }

    > .btn-blue > img {
      width: 1.125rem;
      height: 1.125rem;
      object-fit: contain;
      object-position: center;
      margin-right: .5rem;
    }
  }
</style>
