<template>
  <div  @click="focusInput" @click.self="focused = false" class="tags">
      <div v-for="(item, i) in arrayTags" :key="`arrayTags${i}`" class="teg">
        <p>{{ item }}</p>
        <svg @click="deleteTag(i)" viewBox="0 0 6 7" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="1.06062" height="4.24247" rx="0.530309" transform="matrix(0.707125 0.707089 -0.707125 0.707089 5.25 0.5)" fill="white"/>
          <rect width="1.06062" height="4.24247" rx="0.530309" transform="matrix(-0.707125 0.707088 -0.707125 -0.707089 6 5.74963)" fill="white"/>
          <rect width="1.06062" height="4.24247" rx="0.530309" transform="matrix(-0.707125 -0.707089 0.707125 -0.707089 0.75 6.5)" fill="white"/>
          <rect width="1.06062" height="4.24247" rx="0.530309" transform="matrix(0.707125 -0.707088 0.707125 0.707089 0.00012207 1.25037)" fill="white"/>
        </svg>
      </div>
      <input ref="inputTeg" v-model="teg" @focus="focused = true" :placeholder="placeholder" type="text">
      <ul for="inutTeg" v-if="listSearch.length > 0 && focused === true">
        <li v-for="(item, i) in listSearch" :key="i+'search'" @click="clickList(item)">{{ item }}</li>
      </ul>
  </div>
</template>

<script>
export default {
  name: 'Tags',
  data () {
    return {
      focused: false,
      teg: '',
      arrayTags: [],
      listSearch: []
    }
  },
  props: {
    placeholder: {
      type: String,
      required: false,
      default: 'Введите теги через запятую'
    },
    startValue: {
      type: Array,
      required: false,
      default: function () {
        return []
      }
    }
  },
  methods: {
    deleteTag (i) {
      this.arrayTags.splice(i, 1)
      this.$emit('dataTags', this.arrayTags)
    },
    focusInput () {
      this.$refs.inputTeg.focus()
    },
    addTags (value) {
      if (value.indexOf('/') > -1) {
        if (value.trim().length > 1) {
          this.arrayTags.push(value.substring(0, value.length - 1))
          this.teg = ''
          this.$emit('dataTags', this.arrayTags)
        }
      }
    },
    clickList (value) {
      this.listSearch = []
      this.addTags(value + '/')
      this.$emit('inputValue', value + '/')
    }
  },
  mounted () {
    this.arrayTags = this.startValue
    this.$emit('dataTags', this.arrayTags)
  },
  watch: {
    teg (value) {
      if (this.$store.getters.getTagsBlogs.length !== 0 && value !== '') {
        this.listSearch = this.$store.getters.getTagsBlogs.filter(item => {
          var text = item.toLowerCase()
          return text.includes(value.toLowerCase())
        })
      }
      this.addTags(value)
      this.$emit('inputValue', value)
    },
    startValue (value) {
      this.arrayTags = value
      this.$emit('dataTags', this.arrayTags)
    }
  }
}
</script>

<style scoped lang="scss">
.tags{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 33.75rem;
  background: #EEF4FA;
  border-radius: 3px;
  padding: .35rem 1.25rem;
  min-height: 2.4375rem;
  position: relative;
}
.teg{
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  background: #246CB7;
  border-radius: 4px;
  padding: 0.32rem 0.937rem;
  margin-right:.5rem;

  p{
    font-size: 0.75rem;
    line-height: 150%;
    color: #FFFFFF;
  }
  svg{
    cursor: pointer;
    width: 0.37rem;
    margin-left: 0.62rem;
  }
}
  input{
    display: inline;
    font-size: 0.875rem;
    flex: 1;
    color: #003E78;
    background: #EEF4FA;
    border-radius: 3px;
    margin-top: .3rem;
  }
  ul{
    // display: none;
    position: absolute;
    top: 100%;
    background: #f9fcff;
    padding: 1rem 0;

    font-size: 0.875rem;
    flex: 1;
    color: #003E78;
    border: .1042rem solid #a0c2e1;
    border-radius: 3px;
    margin-top: .3rem;
    z-index: 10;

    li{
      padding: .7rem 1.5rem;
      cursor: pointer;
      &:hover{
        background: rgb(221, 221, 221);
      }
    }
  }
  // input:focus + ul{
  //   display: block;
  // }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .teg {
    height: 2rem;
  }
}
</style>
