<template>
  <div class="inputs__container">
    <tags :startValue="tags"
          @dataTags="dataTags"
          @inputValue="setDelay"
          ref="tagsInput"
          :placeholder="placeholder"/>

    <div class="tags__list" v-if="foundTagsList.length">
      <p v-for="(tag, index) in foundTagsList"
         :key="`foundTag${index}`"
         @click="setTag(tag)">
        {{ tag }}
      </p>
    </div>

  </div>
</template>

<script>
import Tags from '../Admin/Tags'

export default {
  name: 'TagsListSearch',

  props: {
    tags: {
      type: Array,
      required: false,
      default: () => []
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Введите теги через слэш'
    },
    lang: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  data () {
    return {
      foundTagsList: [],
      currentTag: null,
      delay: 0,
      intervalId: null
    }
  },

  created () {
    this.intervalId = setInterval(() => {
      if (this.delay > 0) {
        this.delay -= 100
      } else if (this.currentTag) {
        this.getTagsSearch(this.currentTag)
        this.currentTag = null
      }
    }, 100)
  },

  methods: {
    setDelay (currentTag) {
      this.currentTag = currentTag
      this.$emit('resetPagination')
      if (currentTag) {
        this.delay = 500
      } else {
        this.foundTagsList = []
      }
    },

    async getTagsSearch (currentTag) {
      if (currentTag) {
        let res = await fetch(`${this.$store.getters.getUrlApi}api/news/tags/${currentTag}`, {
          headers: {
            Localization: this.lang ? 'ru' : 'eng'
          }
        })
        res = await res.json()
        if (currentTag) {
          this.foundTagsList = await res
        } else {
          this.foundTagsList = []
        }
      } else {
        this.foundTagsList = []
      }
    },

    dataTags (tags) {
      this.$emit('setTags', tags)
    },

    setTag (tag) {
      this.$refs.tagsInput.addTags(tag + '/')
      this.foundTagsList = []
    }
  },

  components: {
    Tags
  }
}
</script>

<style scoped lang="scss">

  .inputs__container {
    position: relative;

    input {
      width: 19.375rem;
      min-height: 2.45rem;
      margin-right: 0.625rem;
    }

    .tags__list {
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;

      position: absolute;
      width: 19.375rem;
      min-height: 2.45rem;
      left: 0;
      top: 4rem;
      box-sizing: border-box;
      border-radius: .5rem;
      background-color: white;
      box-shadow: 0 2rem 5.5rem rgba(37, 70, 103, 0.35);
      z-index: 100;
      padding: .625rem .9375rem;

      > p {
        display: inline-block;
        padding: .3125rem .75rem;
        box-sizing: border-box;
        background: #EEF4FA;
        border-radius: .25rem;
        color: #246CB7;
        font-size: .75rem;
        line-height: 1.125rem;
        margin-bottom: 0.625rem;
        cursor: pointer;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  @media screen and (max-width: 420px){
    .tags{
      width: 100%;
    }
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .inputs__container .tags__list {
      > p {
        max-width: 100% !important;
      }
    }
  }

</style>
