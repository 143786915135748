<template>
  <div>
    <label :for="name">
      <span class="background" v-if="!blob && !uploadedBlob">
        <img :src="getPreviewSrc">
      </span>

      <img v-else :src="uploadedBlob ? uploadedBlob : blob" class="preview__image">
    </label>
    <input type="file" :id="name" :required="isRequired" @change="setFilePreview($event)" :name="name">
  </div>
</template>

<script>
export default {
  name: 'CustomDropZone',

  props: {
    name: {
      type: String,
      required: true
    },

    uploadedBlob: {
      type: String,
      required: false,
      default: null
    },

    isRequired: {
      type: Boolean,
      required: false,
      default: false
    },

    fileProp: {
      type: Object,
      required: false,
      default: null
    }
  },

  data () {
    return {
      blob: null,
      file: null
    }
  },
  watch: {
    fileProp: {
      deep: true,
      handler () {
        this.blob = window.URL.createObjectURL(this.fileProp)
      }
    }
  },
  methods: {
    setFilePreview (event) {
      const file = event.target.files[0]

      this.blob = window.URL.createObjectURL(file)
      this.file = file

      this.$emit('setFile', this.file)
      this.$emit('setBlob', this.blob)
    },
    removeFile () {
      this.blob = null
      this.file = null
    }
  },
  computed: {
    getPreviewSrc () {
      return this.blob ? this.blob : '/assets/icons/upload_image.svg'
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/scss/utils/vars";

  div {
    position: relative;
  }

  input[type="file"] {
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0;
  }

  label {
    width: 12.1875rem;
    height: 6.375rem;
    box-sizing: border-box;
    padding: 0.3125rem;
    border: 1px dashed #8CA8C2;
    margin-bottom: 0;
    cursor: pointer;

    > .background {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $neutral-placeholder;

      > img {
        width: 3rem;
        height: 3rem;
        object-fit: contain;
        object-position: center;
      }
    }

    .preview__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

</style>
